<template>
  <section>
    <!-- Filters -->
    <OrganizationFilters v-show="showFilters" />
    <b-card no-body>
      <app-data-table
        ref="refOrganizationsListTable"
        table-name="organization-list"
        :fields="organizationsFields"
        :items="organizations"
        :page="page"
        :per-page="numberOfItemsPerPage"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        :busy="organizationsLoading"
        manual-pagination
        :top-table="{
          showSearch: true,
          showFilter: true,
          searchFilterOptions,
          searchText,
        }"
        :actions-table="{ showEdit: $can('OPERATOR_ORGANIZATION_EDIT'), showDelete: $can('OPERATOR_ORGANIZATION_DELETE') }"
        :bottom-table="{ totalCount: pagination.totalItems }"
        @table-select-per-page=";(numberOfItemsPerPage = $event), fetchOrganizations(true)"
        @table-search-text=";(searchText = $event), fetchOrganizations(true)"
        @table-search-filter=";(searchFilterOptions = $event), fetchOrganizations(true)"
        @table-filter="showFilters = $event"
        @pagination=";(page = $event), fetchOrganizations(true)"
        @sort-changed="updateSort"
        @edit="
          $router.push({
            name: 'editOrganization',
            params: {
              id: $event.item.id,
              tab: 'main-information',
            },
          })
        "
        @delete="confirmDeleteAlert($event.item)"
      >
        <!-- LOADING -->
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>

        <!-- Column: Id -->
        <template #cell(id)="{ item }">
          <b-link
            :disabled="!$can('OPERATOR_ORGANIZATION_EDIT')"
            class="font-weight-bold"
            :to="{
              name: 'editOrganization',
              params: {
                id: item.id,
                tab: 'main-information',
              },
            }"
          >
            #{{ item.id }}
          </b-link>
        </template>

        <template #cell(legalName)="{ item }">
          <div class="text-truncate">
            <span>{{ item.legalName }}</span>
          </div>
        </template>

        <template #cell(typologies)="{ item }">
          <ul>
            <li v-for="typology in item.typologies" :key="item.id + typology.typology">{{ typology.typology | enumTranslate('organization_typology') }}</li>
          </ul>
        </template>

        <template #cell(usersNumber)="{ item }">
          <b-badge pill variant="primary">
            {{ item.usersNumber }}
          </b-badge>
        </template>

        <template #cell(servicesNumber)="{ item }">
          <b-badge pill variant="primary">
            {{ item.servicesNumber }}
          </b-badge>
        </template>

        <template #cell(status)="{ item }">
          <b-badge pill :variant="`light-${resolveClassStatusOrganization(item.status)}`" class="text-capitalize">
            {{ item.status | enumTranslate('organization_status') }}
          </b-badge>
        </template>
      </app-data-table>
    </b-card>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'

import UIHelper from '@/helpers/ui'

import AppDataTable from '@/components/AppDataTable.vue'
import { VBModal } from 'bootstrap-vue'
import { deleteOrganizationRequest } from '@/request/globalApi/requests/organizationRequests'
import OrganizationFilters from './OrganizationsFilters.vue'

export default {
  name: 'OrganizationsList',

  directives: { 'b-modal': VBModal },
  components: { OrganizationFilters, AppDataTable },
  mixins: [UIHelper],

  data() {
    return {
      showFilters: false,
      searchFilterOptions: [{ text: this.$t('organization.main_informations.legal_name'), value: 'legalName' }],
      organizationsFields: [
        {
          key: 'id',
          sortable: true,
        },
        {
          key: 'legalName',
          label: this.$t('organization.main_informations.legal_name'),
          sortable: true,
        },
        {
          key: 'typologies',
          label: this.$t('organization.main_informations.typologies'),
        },
        {
          key: 'usersNumber',
          label: this.$t('organization.users_number'),
          tdClass: 'text-center',
          thClass: 'text-center text-nowrap',
        },
        {
          key: 'servicesNumber',
          label: this.$t('organization.services_number'),
          tdClass: 'text-center',
          thClass: 'text-center text-nowrap',
        },
        {
          key: 'status',
          label: this.$t('organization.status'),
          sortable: true,
        },
      ],
    }
  },

  computed: {
    ...mapState('organization', ['organizationsLoading', 'organizations', 'pagination', 'searchText']),
    ...mapState('appConfig', ['lang']),
    // ...mapGetters('organization', ['searchFieldOptions', 'filtersCount']),

    ...mapFields('organization', [
      'paginate.page',
      'paginate.numberOfItemsPerPage',
      'dataTableSort.sortBy',
      'dataTableSort.sortDesc',
      'searchText',
      'searchFilterSelection',
    ]),
    search: {
      get() {
        return this.searchText
      },
      set(value) {
        this.setSearch(value)
      },
    },
  },

  mounted() {
    this.fetchOrganizations()
  },

  methods: {
    ...mapActions('organization', ['fetchOrganizations', 'setSort', 'setSearch', 'clearOrganizationsShowed']),

    updateSort(e) {
      this.sortBy = e.sortBy
      this.sortDesc = e.sortDesc
      this.setSort({ sortBy: e.sortBy, sortDesc: e.sortDesc })
    },
    confirmDeleteAlert(item) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('alert.delete_confirmation.message')}: ${item.legalName}`, {
          title: this.$t('alert.delete_confirmation.title'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: this.$t('common.yes'),
          cancelTitle: this.$t('common.no'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(result => {
          if (result) {
            deleteOrganizationRequest(item.id).then(response => {
              response && this.fetchOrganizations(true)
            })
          }
        })
    },
  },

  beforeRouteLeave(to, from, next) {
    this.clearOrganizationsShowed()
    next()
  },
}
</script>
